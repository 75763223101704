import React from "react";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Layout from "../components/layout";

const parallax = {
    backgroundImage: 'url(../sciences.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>{data.meta.data.story} | Oberlin Photo Stories</title>
            
            <meta name="title" content={data.meta.data.story} />
            <meta name="description" content={data.meta.data.description} />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data.meta.data.metaurl} />
            <meta property="og:title" content={data.meta.data.story} />
            <meta property="og:description" content={data.meta.data.description} />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/sciences.jpg" />
            <meta property="og:image:width" content="1000" />
            <meta property="og:image:height" content="679" />
            <meta property="og:image:alt" content={data.meta.data.alt} />
            <meta property="fb:app_id" content="1571317633107125" />
            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={data.meta.data.metaurl} />
            <meta property="twitter:title" content={data.meta.data.story} />
            <meta property="twitter:description" content={data.meta.data.description} />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/sciences.jpg" />
            <meta property="twitter:image:alt" content={data.meta.data.alt} />
        </Helmet>
        {/* hero */}
        <header>
            <div className="wrapper">
                <div style={parallax} className="hero parallax" role="img" aria-label={data.hero.data.alt}></div>
                <div className="title text-center">
                    <h1 className="display-3 text-light">{data.title.data.title}</h1>
                    <a href="#ss" aria-label="Skip to main content"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                </div>
            </div>
        </header>

        <main>
            <div className="anchor" id="ss"></div>
            <div className="container col-xl-6 col-lg-8 my-4 story">
                {/* Section 2 */}
                <section>
                    <div className="text-center mx-auto lead mt-5" dangerouslySetInnerHTML={{
                    __html: data.intro.data.text.childMarkdownRemark.html,
                    }} />
                    <Fade>
                    <Img fluid={data.rec49kNUsUvADyunk.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec49kNUsUvADyunk.data.alt} />
                    <Img fluid={data.recG2xBzk5Cl9RoYC.data.file.localFiles[0].childImageSharp.fluid} alt={data.recG2xBzk5Cl9RoYC.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recKbt4069k6Alt3Z.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.rec1T7Lt5SkRLTjDE.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec1T7Lt5SkRLTjDE.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recSIXmjTdSbiqIOQ.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recnWm6cxsIBp7o0d.data.file.localFiles[0].childImageSharp.fluid} alt={data.recnWm6cxsIBp7o0d.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec00z9IqqmU0XCMN.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                </section>
                {/* Section 3 */}
                <section>
                    <blockquote className="my-5 pullquote">
                    <p className="mb-0">{data.recklxuk2pXlzeI9w.data.title}</p>
                    <footer className="pullquote-footer">{data.recklxuk2pXlzeI9w.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                    </blockquote>
                    <Fade>
                    <Img fluid={data.recerXydO3TRQRXCf.data.file.localFiles[0].childImageSharp.fluid} alt={data.recerXydO3TRQRXCf.data.alt} className="mt-5"/>
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recClRgUu4loAzaDF.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recWq6OLZogXX7I46.data.file.localFiles[0].childImageSharp.fluid} alt={data.recWq6OLZogXX7I46.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.reckLTlDdXsnbxV0n.data.file.localFiles[0].childImageSharp.fluid} alt={data.reckLTlDdXsnbxV0n.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recTjxXoJX9SJk35p.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.reciVFz8zAQNy0dCU.data.file.localFiles[0].childImageSharp.fluid} alt={data.reciVFz8zAQNy0dCU.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recaEu1oChm4U6Bi7.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recDeV4rgowlX29IG.data.file.localFiles[0].childImageSharp.fluid} alt={data.recDeV4rgowlX29IG.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recnOTanciCK9NKQG.data.file.localFiles[0].childImageSharp.fluid} alt={data.recnOTanciCK9NKQG.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recPGZ50Tr9uNaLLc.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recn54Px8X8sX0ane.data.file.localFiles[0].childImageSharp.fluid} alt={data.recn54Px8X8sX0ane.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rectHfKpW2qNHNWdR.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                </section>
                {/* Section 4 */}
                <section>
                    <blockquote className="my-5 pullquote">
                    <p className="mb-0">{data.recS2eGmkOttxYJzi.data.title}</p>
                    <footer className="pullquote-footer">{data.recS2eGmkOttxYJzi.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                    </blockquote>
                    <Fade>
                    <Img fluid={data.reckz5TiJSAwiBVyg.data.file.localFiles[0].childImageSharp.fluid} alt={data.reckz5TiJSAwiBVyg.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recl45Aq50uOuHR06.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recBggwYJ8AOFkNDf.data.file.localFiles[0].childImageSharp.fluid} alt={data.recBggwYJ8AOFkNDf.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.rec6UQqIPjHKFFpIX.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec6UQqIPjHKFFpIX.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.reccbsOFRF8gLWPKW.data.file.localFiles[0].childImageSharp.fluid} alt={data.reccbsOFRF8gLWPKW.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recNTsx2D9rEca274.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recKkyOen1TfbDNRv.data.file.localFiles[0].childImageSharp.fluid} alt={data.recKkyOen1TfbDNRv.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recVdWTGdKF2KDT4o.data.file.localFiles[0].childImageSharp.fluid} alt={data.recVdWTGdKF2KDT4o.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.reclOaB1hJOKUudRm.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recP9vIO8msukNhDN.data.file.localFiles[0].childImageSharp.fluid} alt={data.recP9vIO8msukNhDN.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rectzqjzrnmZp0IwE.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recrx48BgzfwyG5Si.data.file.localFiles[0].childImageSharp.fluid} alt={data.recrx48BgzfwyG5Si.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec3QHduKKGX81S7I.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recSUD8OhzSVgYG9I.data.file.localFiles[0].childImageSharp.fluid} alt={data.recSUD8OhzSVgYG9I.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.rec4E0JQgdMh1Ej5h.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec4E0JQgdMh1Ej5h.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recnqmYX5UAmbSLMl.data.file.localFiles[0].childImageSharp.fluid} alt={data.recnqmYX5UAmbSLMl.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rectL4PxxVqCjO6d2.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.rec6e7HruDKfgqPVh.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec6e7HruDKfgqPVh.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.reckioIJYrvdC5sSo.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recRqLBXwTqjTo2Vr.data.file.localFiles[0].childImageSharp.fluid} alt={data.recRqLBXwTqjTo2Vr.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec14Atdjcgk8Yzmj.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.rec8q34rxfAyjN8OE.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec8q34rxfAyjN8OE.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recOGQPaDaIyWkK3L.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recq45603TopAPQ4l.data.file.localFiles[0].childImageSharp.fluid} alt={data.recq45603TopAPQ4l.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recQyu6M5XdTOKDFq.data.file.localFiles[0].childImageSharp.fluid} alt={data.recQyu6M5XdTOKDFq.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recBpQQJgdZELAUaL.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.rectr941Mhe7vdYzP.data.file.localFiles[0].childImageSharp.fluid} alt={data.rectr941Mhe7vdYzP.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec5qCZVe72Wp3J9F.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recn96mZHB3ag76Du.data.file.localFiles[0].childImageSharp.fluid} alt={data.recn96mZHB3ag76Du.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recROusf55cXZlTRA.data.file.localFiles[0].childImageSharp.fluid} alt={data.recROusf55cXZlTRA.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recAJyuUQJWMsckDU.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recHpEcA6JZjtE5P2.data.file.localFiles[0].childImageSharp.fluid} alt={data.recHpEcA6JZjtE5P2.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recO6zADZcFdmQEk7.data.file.localFiles[0].childImageSharp.fluid} alt={data.recO6zADZcFdmQEk7.data.alt} className="mt-5" />
                    </Fade>
                </section>
                {/* Section 5 */}
                <section>
                    <blockquote className="my-5 pullquote">
                    <p className="mb-0">{data.recNwe0PY8B8T9Ynr.data.title}</p>
                    <footer className="pullquote-footer">{data.recNwe0PY8B8T9Ynr.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                    </blockquote>
                    <Fade>
                    <Img fluid={data.recMWA9l3O3cTYOlI.data.file.localFiles[0].childImageSharp.fluid} alt={data.recMWA9l3O3cTYOlI.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recuf3jTqGZ8bSA1A.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recnoSD85qPd0qCRc.data.file.localFiles[0].childImageSharp.fluid} alt={data.recnoSD85qPd0qCRc.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recqaFBhRf3JlCq4n.data.file.localFiles[0].childImageSharp.fluid} alt={data.recqaFBhRf3JlCq4n.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recgK5i6kzVJzesHO.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recD5KE2i3S2edLou.data.file.localFiles[0].childImageSharp.fluid} alt={data.recD5KE2i3S2edLou.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.reca0J2325ih2gGwB.data.file.localFiles[0].childImageSharp.fluid} alt={data.reca0J2325ih2gGwB.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec4mIBfOo9593Jef.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recSARdILiqFVa6Q7.data.file.localFiles[0].childImageSharp.fluid} alt={data.recSARdILiqFVa6Q7.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recnBjKZmfVgaRaN4.data.file.localFiles[0].childImageSharp.fluid} alt={data.recnBjKZmfVgaRaN4.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recpHxO5vGu55zFuo.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                </section>
                {/* Section 6 */}
                <section>
                    <blockquote className="my-5 pullquote">
                    <p className="mb-0">{data.rec8eNtbSlmGNhYvN.data.title}</p>
                    <footer className="pullquote-footer">{data.rec8eNtbSlmGNhYvN.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                    </blockquote>
                    <Fade>
                    <Img fluid={data.reccqg45t6DtmYUrs.data.file.localFiles[0].childImageSharp.fluid} alt={data.reccqg45t6DtmYUrs.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recPJNTQBs6YgYQQ9.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.rec6foaEyGaeIlzOc.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec6foaEyGaeIlzOc.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recBVrv2GKK73v738.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recNGgWOZBVnFjjd4.data.file.localFiles[0].childImageSharp.fluid} alt={data.recNGgWOZBVnFjjd4.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recliT39bJaW84O6V.data.file.localFiles[0].childImageSharp.fluid} alt={data.recliT39bJaW84O6V.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recE2NrRopfN7ODig.data.file.localFiles[0].childImageSharp.fluid} alt={data.recE2NrRopfN7ODig.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recZxW722rX0GQG7i.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recm0JfXTBV7UirT4.data.file.localFiles[0].childImageSharp.fluid} alt={data.recm0JfXTBV7UirT4.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recUr4YZuabrCGZqc.data.file.localFiles[0].childImageSharp.fluid} alt={data.recUr4YZuabrCGZqc.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recIDGL22iaFPMFsd.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recRSMWYu1B2l4QZc.data.file.localFiles[0].childImageSharp.fluid} alt={data.recRSMWYu1B2l4QZc.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec9pxxnUCAUzk7k0.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recZDA8KzSmlNJig6.data.file.localFiles[0].childImageSharp.fluid} alt={data.recZDA8KzSmlNJig6.data.alt} className="mt-5" />
                    </Fade>
                    <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recSaYDNQ3MNU3RS9.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                    <Fade>
                    <Img fluid={data.recfhIsEX0UbC4eWK.data.file.localFiles[0].childImageSharp.fluid} alt={data.recfhIsEX0UbC4eWK.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.receBzb7YpDlMNUcJ.data.file.localFiles[0].childImageSharp.fluid} alt={data.receBzb7YpDlMNUcJ.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.recCnufdOkxON2vWz.data.file.localFiles[0].childImageSharp.fluid} alt={data.recCnufdOkxON2vWz.data.alt} className="mt-5" />
                    </Fade>
                    <Fade>
                    <Img fluid={data.rectyRipwK9dM5rKH.data.file.localFiles[0].childImageSharp.fluid} alt={data.rectyRipwK9dM5rKH.data.alt} className="mt-5" />
                    </Fade>
                    <div className="my-5 text-center font-weight-bold" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.recHiZ4pT4RU86PTA.data.text.childMarkdownRemark.html,
                    }} aria-hidden="true" />
                </section>
            </div>
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
    meta: airtable(recordId: {eq: "recjrRNGN3e7zjlDZ"}) {
        data {
          file {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          story
          alt
          metaurl
          description
        }
      }

    hero: airtable(recordId: {eq: "recjrRNGN3e7zjlDZ"}) {
        data {
            alt
        }
    }

    title: airtable(recordId: {eq: "rec9mAs9m1BU1EFGK"}) {
        data {
            title
        }
    }

    intro: airtable(recordId: {eq: "reczpc2POBJEBSudl"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec49kNUsUvADyunk: airtable(recordId: {eq: "rec49kNUsUvADyunk"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recG2xBzk5Cl9RoYC: airtable(recordId: {eq: "recG2xBzk5Cl9RoYC"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 500, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recKbt4069k6Alt3Z: airtable(recordId: {eq: "recKbt4069k6Alt3Z"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec1T7Lt5SkRLTjDE: airtable(recordId: {eq: "rec1T7Lt5SkRLTjDE"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recSIXmjTdSbiqIOQ: airtable(recordId: {eq: "recSIXmjTdSbiqIOQ"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recnWm6cxsIBp7o0d: airtable(recordId: {eq: "recnWm6cxsIBp7o0d"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec00z9IqqmU0XCMN: airtable(recordId: {eq: "rec00z9IqqmU0XCMN"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recklxuk2pXlzeI9w: airtable(data: {identifier: {eq: "recklxuk2pXlzeI9w"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recerXydO3TRQRXCf: airtable(recordId: {eq: "recerXydO3TRQRXCf"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recClRgUu4loAzaDF: airtable(recordId: {eq: "recClRgUu4loAzaDF"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recWq6OLZogXX7I46: airtable(recordId: {eq: "recWq6OLZogXX7I46"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 400, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    reckLTlDdXsnbxV0n: airtable(recordId: {eq: "reckLTlDdXsnbxV0n"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recTjxXoJX9SJk35p: airtable(recordId: {eq: "recTjxXoJX9SJk35p"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reciVFz8zAQNy0dCU: airtable(recordId: {eq: "reciVFz8zAQNy0dCU"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recaEu1oChm4U6Bi7: airtable(recordId: {eq: "recaEu1oChm4U6Bi7"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recDeV4rgowlX29IG: airtable(recordId: {eq: "recDeV4rgowlX29IG"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 400, maxWidth: 1200, cropFocus: CENTER, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recnOTanciCK9NKQG: airtable(recordId: {eq: "recnOTanciCK9NKQG"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recPGZ50Tr9uNaLLc: airtable(recordId: {eq: "recPGZ50Tr9uNaLLc"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recn54Px8X8sX0ane: airtable(recordId: {eq: "recn54Px8X8sX0ane"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rectHfKpW2qNHNWdR: airtable(recordId: {eq: "rectHfKpW2qNHNWdR"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recS2eGmkOttxYJzi: airtable(data: {identifier: {eq: "recS2eGmkOttxYJzi"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    reckz5TiJSAwiBVyg: airtable(recordId: {eq: "reckz5TiJSAwiBVyg"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recl45Aq50uOuHR06: airtable(recordId: {eq: "recl45Aq50uOuHR06"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recBggwYJ8AOFkNDf: airtable(recordId: {eq: "recBggwYJ8AOFkNDf"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec6UQqIPjHKFFpIX: airtable(recordId: {eq: "rec6UQqIPjHKFFpIX"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    reccbsOFRF8gLWPKW: airtable(recordId: {eq: "reccbsOFRF8gLWPKW"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recNTsx2D9rEca274: airtable(recordId: {eq: "recNTsx2D9rEca274"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recKkyOen1TfbDNRv: airtable(recordId: {eq: "recKkyOen1TfbDNRv"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recVdWTGdKF2KDT4o: airtable(recordId: {eq: "recVdWTGdKF2KDT4o"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    reclOaB1hJOKUudRm: airtable(recordId: {eq: "reclOaB1hJOKUudRm"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recP9vIO8msukNhDN: airtable(recordId: {eq: "recP9vIO8msukNhDN"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rectzqjzrnmZp0IwE: airtable(recordId: {eq: "rectzqjzrnmZp0IwE"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recrx48BgzfwyG5Si: airtable(recordId: {eq: "recrx48BgzfwyG5Si"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec3QHduKKGX81S7I: airtable(recordId: {eq: "rec3QHduKKGX81S7I"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recSUD8OhzSVgYG9I: airtable(recordId: {eq: "recSUD8OhzSVgYG9I"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec4E0JQgdMh1Ej5h: airtable(recordId: {eq: "rec4E0JQgdMh1Ej5h"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recnqmYX5UAmbSLMl: airtable(recordId: {eq: "recnqmYX5UAmbSLMl"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rectL4PxxVqCjO6d2: airtable(recordId: {eq: "rectL4PxxVqCjO6d2"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec6e7HruDKfgqPVh: airtable(recordId: {eq: "rec6e7HruDKfgqPVh"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    reckioIJYrvdC5sSo: airtable(recordId: {eq: "reckioIJYrvdC5sSo"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recRqLBXwTqjTo2Vr: airtable(recordId: {eq: "recRqLBXwTqjTo2Vr"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec14Atdjcgk8Yzmj: airtable(recordId: {eq: "rec14Atdjcgk8Yzmj"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec8q34rxfAyjN8OE: airtable(recordId: {eq: "rec8q34rxfAyjN8OE"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recOGQPaDaIyWkK3L: airtable(recordId: {eq: "recOGQPaDaIyWkK3L"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recq45603TopAPQ4l: airtable(recordId: {eq: "recq45603TopAPQ4l"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recQyu6M5XdTOKDFq: airtable(recordId: {eq: "recQyu6M5XdTOKDFq"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recBpQQJgdZELAUaL: airtable(recordId: {eq: "recBpQQJgdZELAUaL"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rectr941Mhe7vdYzP: airtable(recordId: {eq: "rectr941Mhe7vdYzP"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 500, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec5qCZVe72Wp3J9F: airtable(recordId: {eq: "rec5qCZVe72Wp3J9F"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recn96mZHB3ag76Du: airtable(recordId: {eq: "recn96mZHB3ag76Du"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recROusf55cXZlTRA: airtable(recordId: {eq: "recROusf55cXZlTRA"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recAJyuUQJWMsckDU: airtable(recordId: {eq: "recAJyuUQJWMsckDU"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recHpEcA6JZjtE5P2: airtable(recordId: {eq: "recHpEcA6JZjtE5P2"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recO6zADZcFdmQEk7: airtable(recordId: {eq: "recO6zADZcFdmQEk7"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recNwe0PY8B8T9Ynr: airtable(data: {identifier: {eq: "recNwe0PY8B8T9Ynr"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recMWA9l3O3cTYOlI: airtable(recordId: {eq: "recMWA9l3O3cTYOlI"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recuf3jTqGZ8bSA1A: airtable(recordId: {eq: "recuf3jTqGZ8bSA1A"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recnoSD85qPd0qCRc: airtable(recordId: {eq: "recnoSD85qPd0qCRc"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recqaFBhRf3JlCq4n: airtable(recordId: {eq: "recqaFBhRf3JlCq4n"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recgK5i6kzVJzesHO: airtable(recordId: {eq: "recgK5i6kzVJzesHO"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recD5KE2i3S2edLou: airtable(recordId: {eq: "recD5KE2i3S2edLou"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    reca0J2325ih2gGwB: airtable(recordId: {eq: "reca0J2325ih2gGwB"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec4mIBfOo9593Jef: airtable(recordId: {eq: "rec4mIBfOo9593Jef"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recSARdILiqFVa6Q7: airtable(recordId: {eq: "recSARdILiqFVa6Q7"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 700, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recnBjKZmfVgaRaN4: airtable(recordId: {eq: "recnBjKZmfVgaRaN4"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recpHxO5vGu55zFuo: airtable(recordId: {eq: "recpHxO5vGu55zFuo"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec8eNtbSlmGNhYvN: airtable(data: {identifier: {eq: "rec8eNtbSlmGNhYvN"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    reccqg45t6DtmYUrs: airtable(recordId: {eq: "reccqg45t6DtmYUrs"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recPJNTQBs6YgYQQ9: airtable(recordId: {eq: "recPJNTQBs6YgYQQ9"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec6foaEyGaeIlzOc: airtable(recordId: {eq: "rec6foaEyGaeIlzOc"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recBVrv2GKK73v738: airtable(recordId: {eq: "recBVrv2GKK73v738"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recNGgWOZBVnFjjd4: airtable(recordId: {eq: "recNGgWOZBVnFjjd4"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recliT39bJaW84O6V: airtable(recordId: {eq: "recliT39bJaW84O6V"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 500, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recE2NrRopfN7ODig: airtable(recordId: {eq: "recE2NrRopfN7ODig"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recZxW722rX0GQG7i: airtable(recordId: {eq: "recZxW722rX0GQG7i"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recm0JfXTBV7UirT4: airtable(recordId: {eq: "recm0JfXTBV7UirT4"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 500, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recUr4YZuabrCGZqc: airtable(recordId: {eq: "recUr4YZuabrCGZqc"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recIDGL22iaFPMFsd: airtable(recordId: {eq: "recIDGL22iaFPMFsd"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recRSMWYu1B2l4QZc: airtable(recordId: {eq: "recRSMWYu1B2l4QZc"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec9pxxnUCAUzk7k0: airtable(recordId: {eq: "rec9pxxnUCAUzk7k0"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recZDA8KzSmlNJig6: airtable(recordId: {eq: "recZDA8KzSmlNJig6"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recSaYDNQ3MNU3RS9: airtable(recordId: {eq: "recSaYDNQ3MNU3RS9"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recfhIsEX0UbC4eWK: airtable(recordId: {eq: "recfhIsEX0UbC4eWK"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    receBzb7YpDlMNUcJ: airtable(recordId: {eq: "receBzb7YpDlMNUcJ"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 500, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recCnufdOkxON2vWz: airtable(recordId: {eq: "recCnufdOkxON2vWz"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 600, maxWidth: 1200, cropFocus: SOUTH, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rectyRipwK9dM5rKH: airtable(recordId: {eq: "rectyRipwK9dM5rKH"}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(maxHeight: 800, maxWidth: 1200, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recHiZ4pT4RU86PTA: airtable(recordId: {eq: "recHiZ4pT4RU86PTA"}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }
}  
`;